/* eslint-disable no-unused-expressions */
import React from "react";
import * as THREE from "three";
import { useGLTF, useTexture, Decal } from "@react-three/drei";
import { useCustomization } from "../../../Context/CustomizerContext";

const Model = (props) => {
    const { paternPocket, setPaternPocket, logoClientUrl, holderAponColor, logoUrl } = useCustomization();
    const { nodes, materials } = useGLTF("/models/Apon_long.glb");

    const logo = useTexture(`/logo/${logoUrl.name}.png`)
    const logoBrand = useTexture('/logo/LOGO-01.png');
    const logoClient = useTexture(`${logoClientUrl}`)

    const paternTextureProps = useTexture({
        map: `/patern/${paternPocket.name}.png`
    })

    paternTextureProps.map.repeat.set(2.5, 3);
    paternTextureProps.map.wrapS = paternTextureProps.map.wrapT = THREE.RepeatWrapping;

    console.log(paternPocket)
    return (
        <group {...props} dispose={null}>
            <mesh
                castShadow
                receiveShadow
                geometry={nodes.apon_main.geometry}
                material={materials.apon_main}
            >
                <meshStandardMaterial color={"#1c1c1c"} />
                <Decal
                    // debug
                    position={[0.0, 0.51, 0.03]}
                    rotation={[0, 0, 0]}
                    scale={[0.03, 0.03, 0.03]}
                >
                    <meshPhysicalMaterial
                        map={logoBrand}
                        transparent
                        polygonOffset
                        polygonOffsetFactor={-1}
                        iridescence={0}
                        iridescenceIOR={1}
                        iridescenceThicknessRange={[0, 1400]}
                        roughness={1}
                        clearcoat={0}
                        metalness={0}
                        toneMapped={false}
                    />
                </Decal>

                {logoClient !== '' ? (
                    <Decal
                        // debug
                        position={[0.0, 0.48, 0.03]}
                        rotation={[0, 0, 0]}
                        scale={[0.03, 0.03, 0.03]}
                    >
                        <meshPhysicalMaterial
                            map={logoClient}
                            transparent
                            polygonOffset
                            polygonOffsetFactor={-1}
                            iridescence={0}
                            iridescenceIOR={1}
                            iridescenceThicknessRange={[0, 1400]}
                            roughness={1}
                            clearcoat={0}
                            metalness={0}
                            toneMapped={false}
                        />
                    </Decal>

                ) : ''}

            </mesh>
            <mesh
                castShadow
                receiveShadow
                geometry={nodes.top.geometry}
                material={materials.top}
            >
                <meshStandardMaterial color={"#1c1c1c"} />
            </mesh>
            <mesh
                castShadow
                receiveShadow
                geometry={nodes.pocket.geometry}
                material={materials.pocket}
            >
                <meshStandardMaterial {...paternTextureProps} />
                {paternPocket.name === 'PATTERN-03' ? (
                    <Decal
                        // debug
                        position={[0.0, 0.37, 0.03]}
                        rotation={[0, 0, 0]}
                        scale={[0.045, 0.045, 0.045]}
                    >
                        <meshPhysicalMaterial
                            map={logo}
                            transparent
                            polygonOffset
                            polygonOffsetFactor={-1}
                            iridescence={0}
                            iridescenceIOR={1}
                            iridescenceThicknessRange={[0, 1400]}
                            roughness={1}
                            clearcoat={0}
                            metalness={0}
                            toneMapped={false}
                        />
                    </Decal>
                ) : ''}
            </mesh>
            <mesh
                castShadow
                receiveShadow
                geometry={nodes.decals.geometry}
                material={materials.decals}
            >
                <meshStandardMaterial color={"#231f20"} />
            </mesh>
            <mesh
                castShadow
                receiveShadow
                geometry={nodes.metal_holder.geometry}
                material={materials['Material.008']}
            />
            <mesh
                castShadow
                receiveShadow
                geometry={nodes.Crop.geometry}
                material={materials.Crop}
            >
                <meshStandardMaterial color={holderAponColor.color} />
            </mesh>
            <group
                position={[-0.001, 0.533, 0.031]}
                rotation={[0.089, 0, 0]}>
                <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere001.geometry}
                    material={materials['Material.008']}
                />
                <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere001_1.geometry}
                    material={materials['Material.004']}
                />
                <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere001_2.geometry}
                    material={materials['Material.002']}
                />
                <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere001_3.geometry}
                    material={materials['Material.003']}
                />
                <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Sphere001_4.geometry}
                    material={materials['Material.001']}
                />
            </group>
            <mesh
                castShadow
                receiveShadow
                geometry={nodes.apon_main001.geometry}
                material={materials['apon_main.001']}
            >
                <meshStandardMaterial color={"#818181"} />
            </mesh>
        </group>
    );
}

export default Model

useGLTF.preload("/models/Apon_long.glb");