import { useLocation, useParams } from 'react-router-dom';

// MUI
import { Box, Stack } from '@mui/material'

// Fiber three
import { Canvas } from "@react-three/fiber"
import { Environment, OrbitControls, Center } from "@react-three/drei"

// Images
import imgInfo from './../../assets/img/infobox.png'

//Components

import Topbar from '../Layout/Topbar';

import FemaleKimonoPreview from './kimono/female';
import MaleKimonoPreview from './kimono/male';
import AponPreview from './apon';
import ButtonBackPreview from '../Helper/ButtonBackPreview';

const Preview = (params) => {

    const location = useLocation();
    const { logo, color, category, title } = location.state;

    const renderProductCanvas = () => {
        if (title === 'ΑΝ∆ΡΙΚΟ') return (<MaleKimonoPreview logo={logo} color={color} category={category} title={title} />)
        if (title === 'ΓΥΝΑΙΚΕΙΟ') return (<FemaleKimonoPreview logo={logo} color={color} category={category} title={title} />)
        if (category === 'ΠΟΔΙΕΣ') return (<AponPreview logo={logo} color={color} category={category} title={title} />)
    }

    return (
        <>
            <Topbar />
            <Box
                component="main"
                sx={{ bgcolor: '#eeefef' }}
            >
                <Stack sx={{ height: '100vh' }} mt={5}>
                    <Canvas gl={{ preserveDrawingBuffer: true }} shadows camera={{ position: [2, 0, 7], fov: 2.8 }}>
                        <ambientLight intensity={0.5} />
                        <spotLight position={[10, 10, 10]} angle={0.5} penumbra={1} />
                        <Center>
                            {renderProductCanvas()}
                        </Center>
                        <Environment preset="warehouse" resolution={1024} />
                        <OrbitControls minPolarAngle={Math.PI / 2} maxPolarAngle={Math.PI / 2} enableZoom={true} enablePan={true} />
                    </Canvas>
                    <Box
                        className='infobox'
                        component="img"
                        position='fixed'
                        sx={{
                            bottom: '50px',
                            right: 50,
                            height: 190,
                            width: 118,
                        }}
                        alt="info"
                        src={imgInfo}
                    />
                </Stack>
                <ButtonBackPreview />
            </Box>

        </>
    )
}

export default Preview