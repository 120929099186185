import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    items: [],
}

const cartSlice = createSlice({
    name: 'items',
    initialState,
    reducers: {
        addCartItem: (state, action) => {
            state.items.push(action.payload)
        },
        removeCartItem: (state, action) => {
            state.items = state.items.filter((item) => item.id !== action.payload)
        },
        removeCartItemAll(state) {
            Object.assign(state, initialState)
        }
    }
})

export const { addCartItem, removeCartItem, removeCartItemAll } = cartSlice.actions;
export const cartReducer = cartSlice.reducer;