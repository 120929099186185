import { useEffect } from "react"
import { Routes, Route } from "react-router-dom"

// Redux 
import { useDispatch, useSelector } from 'react-redux'
import { fetchAuthMe } from "./redux/slices/Auth";

// ProtectRoutes
import ProtectedRoute from './Context/ProtectRoute';

// pages
import Login from "./pages/Auth/Login";
import Register from "./pages/Auth/Register";
import Home from './pages/Home'
import Dashboard from "./pages/Dashboard";
import Categories from "./pages/Menu";
import Products from "./pages/Menu/Products";
import Customize from './pages/Customize'
import Checkout from './pages/Checkout'
import SingleOrder from "./pages/Dashboard/pages/Orders/SingleOrder";
import Shops from './pages/Dashboard/pages/Users/Shops'
import ResetPassword from "./pages/Auth/ResetPassword";
import Preview from "./components/Preview";

const App = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAuthMe());
  }, []);

  return (
    <>
      <Routes>
        <Route path='/' element={<Login />} />
        <Route path='/login' element={<Login />} />
        <Route path='/register' element={<Register />} />
        <Route path='/reset-password/:id/:token/:userId' element={<ResetPassword />} />

        <Route path='/home' element={
          <ProtectedRoute>
            <Home />
          </ProtectedRoute>
        } />

        <Route path='/dashboard' element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        } />

        <Route path='/create' element={
          <ProtectedRoute>
            <Categories />
          </ProtectedRoute>
        } />

        <Route path='/:category' element={
          <ProtectedRoute>
            <Products />
          </ProtectedRoute>
        } />

        <Route path='/customize/:id' element={
          <ProtectedRoute>
            <Customize />
          </ProtectedRoute>
        } />

        <Route path='/checkout' element={
          <ProtectedRoute>
            <Checkout />
          </ProtectedRoute>
        } />

        <Route path='/single-order/:id' element={
          <ProtectedRoute>
            <SingleOrder />
          </ProtectedRoute>
        } />

        <Route path='/shops/:id' element={
          <ProtectedRoute>
            <Shops />
          </ProtectedRoute>
        } />

        <Route path='/preview' element={
          <ProtectedRoute>
            <Preview />
          </ProtectedRoute>
        } />

      </Routes>
    </>
  )
}

export default App