/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from "react";
import { useLocation, useParams } from 'react-router-dom';
import { useGLTF, useTexture, Decal } from "@react-three/drei";

const FemaleKimonoPreview = (props) => {

    const location = useLocation();
    const { logo, color, category, title } = location.state;

    const { nodes } = useGLTF("/models/Kimono_Female.gltf");

    const [logoRef, setLogoRef] = useState('/logo/clear.png')

    useEffect(() => {
        if (logo === 'ΧΩΡΙΣ LOGO') {
            setLogoRef('/logo/clear.png')
        } else {
            setLogoRef(logo)
        }
    }, [])

    const logoBrand = useTexture('/logo/LOGO-01.png');
    const logoClient = useTexture(`${logoRef}`)

    return (
        <group {...props} dispose={null}>
            <mesh
                castShadow
                receiveShadow
                geometry={nodes.Female_Hight_Decim_Merge.geometry}
                material={nodes.Female_Hight_Decim_Merge.material}
                rotation={[Math.PI / 2, 0, 0]}
                scale={0.01}
            >
                <meshStandardMaterial color={"#1c1c1c"} />

                <Decal
                    //debug
                    position={[-2.5, 3, -52]}
                    rotation={[-11.2, -13.2, 0.1]}
                    scale={[2.8, -2.8, 2.8]}
                >
                    <meshPhysicalMaterial
                        map={logoBrand}
                        transparent
                        polygonOffset
                        polygonOffsetFactor={-1}
                        iridescence={0}
                        iridescenceIOR={1}
                        iridescenceThicknessRange={[0, 1400]}
                        roughness={1}
                        clearcoat={0}
                        metalness={0}
                        toneMapped={false}
                    />
                </Decal>

                <Decal
                    //debug
                    position={[-9.4, -2.5, -52]}
                    rotation={[-14.2, -14, -0.1]}
                    scale={[2.8, 2, 6]}
                >
                    <meshPhysicalMaterial
                        map={logoClient}
                        transparent
                        polygonOffset
                        polygonOffsetFactor={-1}
                        iridescence={0}
                        iridescenceIOR={1}
                        iridescenceThicknessRange={[0, 1400]}
                        roughness={1}
                        clearcoat={0}
                        metalness={0}
                        toneMapped={false}
                    />
                </Decal>

            </mesh>
            <mesh
                castShadow
                receiveShadow
                geometry={nodes.Pocket.geometry}
                material={nodes.Pocket.material}
                rotation={[Math.PI / 2, 0, 0]}
                scale={0.01}
            >
                <meshStandardMaterial color={"#1c1c1c"} />
            </mesh>
            <mesh
                castShadow
                receiveShadow
                geometry={nodes.Collar.geometry}
                material={nodes.Collar.material}
                rotation={[Math.PI / 2, 0, 0]}
                scale={0.01}
            >
                <meshStandardMaterial color={color} />

            </mesh>
        </group>
    );
}

export default FemaleKimonoPreview

useGLTF.preload("/models/Kimono_Male.gltf");