import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom'

import { Provider } from 'react-redux'
import store from './redux/store'

import { CustomizationProvider } from './Context/CustomizerContext'

import './index.css';
import { CssBaseline, ThemeProvider } from '@mui/material'
import theme from './theme'

// Toast
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <ToastContainer />
    <BrowserRouter>
      <Provider store={store}>
        <CustomizationProvider>
          <App />
        </CustomizationProvider>
      </Provider>
    </BrowserRouter>
  </ThemeProvider>
);
