import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import axios from '../../../../axios'

import { TableContainer, Paper, Grid, } from '@mui/material';

// Redux 
import { useDispatch, useSelector } from 'react-redux'
import { fetchUsers } from "../../../../redux/slices/User";

import AdminTable from './Table/AdminTable';
import SellerTable from './Table/SellerTable';

const Users = () => {

  const dispatch = useDispatch();
  const userAuth = useSelector((state) => state.auth.data)

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    dispatch(fetchUsers())
    setLoading(false)
  }, [loading])

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12} lg={12}>
        <TableContainer component={Paper} sx={{ borderRadius: '10px' }}>
          {
            userAuth && userAuth.role === 'admin' ? (
              <AdminTable />
            ) : (
              <SellerTable />
            )
          }
        </TableContainer>
      </Grid>
    </Grid>
  )
}

export default Users