import React from 'react'
import { useNavigate } from "react-router-dom"

import Image from './Image'

// Images
import backImage from '../../assets/img/ELEMENTS-10.png'

const ButtonBackPreview = () => {
    const navigate = useNavigate()
    return (
        <Image src={backImage} onClick={() => navigate(-1)} sx={{ position: 'fixed', bottom: '20px', left: '10px', zIndex: '9999' }} />
    )
}

export default React.memo(ButtonBackPreview)