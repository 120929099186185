import React from 'react'
// MUI
import { Button, TextField, Box, Grid, Typography } from '@mui/material';

// Components
import Image from '../../components/Helper/Image'

// React form
import { useForm } from 'react-hook-form'

// Images
import backgroundLogo from '../../assets/img/logo_black.png'

import axios from '../../axios'

import { useNavigate, useParams } from 'react-router-dom';

const ResetPassword = () => {

    const navigate = useNavigate()

    const { id, token, userId } = useParams()

    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm({
        defaultValues: {
            password: '',
            password: '',
        },
        mode: 'onChange',
    });

    const onSubmit = async (values) => {

        const data = {
            password: values.password,
        }
        await axios.patch(`/users/changePass/${userId}`, data)
        navigate('/login')
    }

    return (
        <Grid container component="main" sx={{ height: '100vh', overflowY: 'hidden' }}>
            <Grid
                item
                xs={false}
                sm={4}
                md={7}
                sx={{
                    backgroundColor: `#fff`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    position: 'relative'
                }}
            >
                <Image src={backgroundLogo}
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '80%'
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={8} md={5} elevation={6}>
                <Box
                    sx={{
                        height: '100vh',
                        mx: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center'
                    }}
                >
                    <Box mb={5} textAlign='center'>
                        <Typography component="h1" variant="h4">PROFESSIONAL</Typography>
                        <Typography component="h1" variant="h4" sx={{ color: '#f2c300' }}>CLOTHING</Typography>
                        <Typography component="h1" variant="h4">APPLICATION</Typography>
                    </Box>
                    <Box noValidate sx={{ mt: 5, input: { color: '#f2c300' } }}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <TextField
                                margin="normal"
                                fullWidth
                                label="New Password"
                                type='password'
                                error={Boolean(errors.password?.message)}
                                helperText={errors.password?.message}
                                {...register('password', { required: 'Please enter password' })}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                label="Repeat New Password"
                                type="password"
                                error={Boolean(errors.password?.message)}
                                helperText={errors.password?.message}
                                {...register('password Re', { required: 'Please enter password' })}
                            />
                            <Button
                                disabled={!isValid}
                                size="large"
                                fullWidth
                                variant="contained"
                                type='submit'
                                sx={{
                                    mt: 3,
                                    mb: 2,
                                    fontSize: '20px'
                                }}
                            >
                                Change
                            </Button>
                        </form>
                    </Box>
                </Box>
            </Grid>
        </Grid >
    )
}

export default React.memo(ResetPassword)