import React from 'react'

//MUI
import { Container, Grid } from "@mui/material";

// Components
import Topbar from '../../components/Layout/Topbar'
import MenuCard from '../../components/Menu/MenuCard';
import ButtonBack from '../../components/Helper/ButtonBack';
import SendMail from '../../components/Helper/SendMail';

// Images
import Apon from '../../assets/img/ELEMENTS-04.png'
import Kimono from '../../assets/img/ELEMENTS-08.png'

const Categories = () => {
  return (
    <>
      <Topbar />
      <Container
        maxWidth="lg"
        sx={{
          flexDirection: 'column',
          justifyContent: 'center',
          display: 'flex',
        }}
      >
        <Grid container spacing={4} direction='row' sx={{ height: '100vh', alignContent: 'center' }}>
          <Grid item xs={12} sm={6} md={6}
            sx={{
              borderRadius: '10px',
            }}>
            <MenuCard
              title={'ΠΟΔΙΕΣ'}
              icon={Apon}
              link={'podies'}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}
            sx={{
              borderRadius: '10px',
            }}
          >
            <MenuCard
              title={'ΚΙΜΟΝΟ'}
              icon={Kimono}
              link={'kimono'}
            />
          </Grid>
        </Grid>
      </Container>
      <SendMail />
      <Container sx={{ marginTop: '-50px' }} maxWidth={false} >
        <ButtonBack />
      </Container>
    </>
  )
}

export default Categories