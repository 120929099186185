import React from 'react'
import { Container, Grid, Chip } from "@mui/material";

import DeleteIcon from '@mui/icons-material/Delete';

const SendMail = () => {

    const sendMail = () => {
        window.location = "mailto:linkworldwidegr@gmail.com"
    }

    return (
        <Container sx={{ marginTop: '-20px' }} maxWidth={false} >
            <Chip
                onClick={sendMail}
                label="Τεχνική υποστήριξη"
                deleteIcon={<DeleteIcon />}
                variant="outlined"
                sx={{ float: 'right' }}
            />
        </Container>
    )
}

export default SendMail