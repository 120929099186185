import React, { useEffect, useState, useRef } from 'react'
import { Stack, Paper, Grid, Table, TableContainer, TableHead, TableCell, TableRow, TableBody, Button } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux'
import { fetchOrders } from "../../../../redux/slices/Order";

import { DownloadTableExcel } from 'react-export-table-to-excel';

const Reports = () => {

  const dispatch = useDispatch();
  const userAuth = useSelector((state) => state.auth.data)

  const orders = useSelector(state => state.order.items)

  const [filter, setFilter] = useState()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    dispatch(fetchOrders())
    setLoading(false)
  }, [loading])

  // Filter function
  useEffect(() => {
    function getUniqueListBy(orders, key) {
      return [...new Map(orders.map(item => [item[key], item])).values()]
    }
    setFilter(getUniqueListBy(orders, 'emailSeller'))
  }, [])

  const tableRef = useRef(null);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12} lg={12}>

        <DownloadTableExcel
          filename="users table"
          sheet="users"
          currentTableRef={tableRef.current}
        >
          <Button variant="contained" sx={{ mt: 2, mb: 2, float: 'right' }}>Export excel</Button>
        </DownloadTableExcel>

        <TableContainer component={Paper} sx={{ borderRadius: '10px' }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table" ref={tableRef}>
            <TableHead>
              <TableRow>
                <TableCell align="left">Όνομα πωλητή</TableCell>
                <TableCell align="left">Κωδικός πελάτη</TableCell>
                <TableCell align="left">Εμπορική Επωνυμία Καταστήματος</TableCell>
                <TableCell align="left">Επωνυμία Πελάτη</TableCell>
                <TableCell align="left">Approver</TableCell>
                <TableCell align="left">Τηλέφωνο πωλητή</TableCell>
                <TableCell align="left">Ημερομηνία παραγγελίας</TableCell>
                <TableCell align="left">Προϊόν Ποδιά</TableCell>
                <TableCell align="left">Προϊόν Κιμονό Γυναικείο</TableCell>
                <TableCell align="left">Προϊόν Κιμονό Αντρικό</TableCell>
                <TableCell align="left">Κόστος</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders?.map((obj, idx) =>
              (
                obj.items?.map((n) => (
                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align="left">{obj.nameSeller} {obj.surnameSeller}</TableCell>
                    <TableCell align="left">{obj.sellerCode}</TableCell>
                    <TableCell align="left">{obj.storeName}</TableCell>
                    <TableCell align="left">{obj.customerBrand}</TableCell>
                    <TableCell align="left">{obj.approvers}</TableCell>
                    <TableCell align="left">{obj.phone}</TableCell>
                    <TableCell align="left">{new Date(obj.createdAt).toLocaleDateString()}</TableCell>
                    <TableCell align="left">
                      {n.category === 'ΠΟΔΙΕΣ' ? n.quntity : '-'}
                    </TableCell>
                    <TableCell align="left">
                      {n.category === 'ΚΙΜΟΝΟ' && n.title === 'ΓΥΝΑΙΚΕΙΟ' ? n.quntity : '-'}
                    </TableCell>
                    <TableCell align="left">
                      {n.category === 'ΚΙΜΟΝΟ' && n.title === 'ΑΝ∆ΡΙΚΟ' ? n.quntity : '-'}
                    </TableCell>
                    <TableCell align="left">
                      {n.category === 'ΠΟΔΙΕΣ' ? (47.72 * n.quntity).toFixed(2) + '€' : ''}
                      {n.category === 'ΚΙΜΟΝΟ' ? (64.40 * n.quntity).toFixed(2) + '€' : ''}
                    </TableCell>
                  </TableRow>))
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  )
}

export default Reports