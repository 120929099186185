/* eslint-disable no-unused-expressions */

import React from "react";
import { useGLTF, useTexture, Decal } from "@react-three/drei";
import { useCustomization } from "../../../Context/CustomizerContext";

const Model = (props) => {
    const { collarColor, setCollarColor, logoClientUrl } = useCustomization();
    const { nodes } = useGLTF("/models/Kimono_Male.gltf");

    const logoBrand = useTexture('/logo/LOGO-01.png');
    const logoClient = useTexture(`${logoClientUrl}`)

    return (
        <group {...props} dispose={null}>
            <mesh
                castShadow
                receiveShadow
                geometry={nodes.Male_Hight_Merge_Dec.geometry}
                material={nodes.Male_Hight_Merge_Dec.material}
                rotation={[Math.PI / 2, 0, 0]}
                scale={0.01}
            >
                <meshStandardMaterial color={"#1c1c1c"} />

                <Decal
                    //debug
                    position={[-4.3, 2.9, -55]}
                    rotation={[-11.2, -12.9, 0.2]}
                    scale={[2.5, -2.8, 5]}
                >
                    <meshPhysicalMaterial
                        map={logoBrand}
                        transparent
                        polygonOffset
                        polygonOffsetFactor={-1}
                        iridescence={0}
                        iridescenceIOR={1}
                        iridescenceThicknessRange={[0, 1400]}
                        roughness={1}
                        clearcoat={0}
                        metalness={0}
                        toneMapped={false}
                    />
                </Decal>

                {logoClient !== '' ? (
                    <Decal
                        //debug
                        position={[-9.4, -2.5, -54]}
                        rotation={[-14.2, -14, -0.1]}
                        scale={[2.8, 2, 6]}
                    >
                        <meshPhysicalMaterial
                            map={logoClient}
                            transparent
                            polygonOffset
                            polygonOffsetFactor={-1}
                            iridescence={0}
                            iridescenceIOR={1}
                            iridescenceThicknessRange={[0, 1400]}
                            roughness={1}
                            clearcoat={0}
                            metalness={0}
                            toneMapped={false}
                        />
                    </Decal>

                ) : ''}
            </mesh>
            <mesh
                castShadow
                receiveShadow
                geometry={nodes.Male_Hight_Merge_Dec1.geometry}
                material={nodes.Male_Hight_Merge_Dec1.material}
                rotation={[Math.PI / 2, 0, 0]}
                scale={0.01}
            >
                <meshStandardMaterial color={"#1c1c1c"} />
            </mesh>
            <mesh
                castShadow
                receiveShadow
                geometry={nodes.Extract2.geometry}
                material={nodes.Extract2.material}
                rotation={[Math.PI / 2, 0, 0]}
                scale={0.01}
            >
                <meshStandardMaterial color={collarColor.color} />
            </mesh>
        </group>
    );
}

export default Model

useGLTF.preload("/models/Kimono_Male.gltf");