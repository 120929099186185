import { useNavigate } from "react-router-dom";

// MUI
import { Card, Typography, CardActionArea } from "@mui/material"

// Images
import Image from '../Helper/Image';

const ProductCard = ({ id, title, icon, name, category, description }) => {

    const navigate = useNavigate();

    return (
        <CardActionArea onClick={() => navigate(`/customize/${id}`, { state: { title: title, name: name, category: category, description: description } })}>
            <Card
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    borderRadius: '8px',
                }}
                elevation={0}
            >
                <Image src={icon} sx={{ height: 209, width: 160, padding: '20px', marginTop: '30px' }} />
                <Typography my={5} variant='h3'>{title}</Typography>
            </Card>
        </CardActionArea>
    )
}

export default ProductCard