import React, { useState, useEffect } from 'react'

import { Box, Tab, Container, Grid, Paper } from '@mui/material';

import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import axios from './../../axios'

// Redux 
import { useDispatch, useSelector } from 'react-redux'
import { fetchUsers } from "../../redux/slices/User";

import Topbar from '../../components/Layout/Topbar'
import ButtonBack from '../../components/Helper/ButtonBack';
import SendMail from '../../components/Helper/SendMail';

import Users from './pages/Users'
import Profile from './pages/Profile'
import Orders from './pages/Orders'
import Reports from './pages/Reports'
import Shops from './pages/Shops'
import AdminTools from './pages/AdminTool';

const Dashboard = () => {

  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const dispatch = useDispatch();
  const userAuth = useSelector((state) => state.auth.data)

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    dispatch(fetchUsers())
    setLoading(false)
  }, [loading])

  return (
    <div>
      <Topbar />
      <div sx={{
        flexDirection: 'column',
        justifyContent: 'center',
        display: 'flex',
        height: '35px',
        cursor: 'pointer'
      }}>
        <Container
          maxWidth="false"
          sx={{
            flexDirection: 'column',
            justifyContent: 'spaceBetween',
            display: 'flex',
            position: 'relative',
            marginBottom: '50px',
            minHeight: '85vh'
          }}>
          <Grid container spacing={4} direction='row' sx={{ height: '100%', width: '100%', marginLeft: '0' }}>
            <Box sx={{ width: '100%', typography: 'body1', mt: 20 }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  {
                    userAuth && userAuth.role === 'admin' ? (
                      <TabList onChange={handleChange}>
                        <Tab label="Παραγγελίες" value="1" />
                        <Tab label="Πωλητές" value="2" />
                        <Tab label="Προφίλ" value="3" />
                        <Tab label="Reports" value="4" />
                        {userAuth.email === 'demoappsgr@gmail.com' ? (
                          <Tab label="Admin tools" value="5" />
                        ) : ('')}
                      </TabList>

                    ) : (
                      <TabList onChange={handleChange}>
                        <Tab label="Παραγγελίες" value="1" />
                        <Tab label="Καταστήματα" value="2" />
                        <Tab label="Προφίλ" value="3" />
                        <Tab label="Reports" value="4" />
                      </TabList>
                    )}
                </Box>
                <TabPanel value="1">
                  <Orders />
                </TabPanel>
                {
                  userAuth && userAuth.role === 'admin' ? (
                    <TabPanel value="2">
                      <Users />
                    </TabPanel>
                  ) : (
                    <TabPanel value="2">
                      <Shops />
                    </TabPanel>
                  )}
                <TabPanel value="3">
                  <Profile />
                </TabPanel>
                <TabPanel value="4">
                  <Reports />
                </TabPanel>
                <TabPanel value="5">
                  <AdminTools />
                </TabPanel>
              </TabContext>
            </Box>
          </Grid>
        </Container>
        <SendMail />
        <ButtonBack />
      </div>
    </div>
  )
}

export default Dashboard