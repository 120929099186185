
import React, { useEffect, useState } from 'react'

import {
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableBody,
    TableRow,
    Paper,
    Button,
    Stack,
    TextField,
    Grid,
    Typography,
    Chip,
    Container,
} from '@mui/material';

// Redux 
import { useDispatch, useSelector } from 'react-redux'
import { fetchUsers } from "../../../../redux/slices/User";

import axios from './../../../../axios'

// Tostofy
import { ToastContainer, toast } from 'react-toastify';

const AdminTools = () => {

    const dispatch = useDispatch();
    const users = useSelector((state) => state.user.items)
    const loading = useSelector((state) => state.user.status)
    const [userData, setUserData] = useState('')
    const [user_name, setUserName] = useState()
    const [user_email, setUserEmail] = useState()

    useEffect(() => {
        setUserData(users)
    }, [])

    const handleChange = ({id, name, email}) => {
        setUserName(name)
        setUserEmail(email)
    }

    const handleSubmit = async (id) => { 
        const data = {
            name: user_name,
            email: user_email
        }
        await axios.patch(`/users/${id}`, data);

        toast(' ', {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            progress: undefined,
            theme: "light",
        });
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
                <Container maxWidth="false" component={Paper} sx={{ borderRadius: '10px' }}>
                    <Stack pt={2}>

                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">Role</TableCell>
                                    <TableCell align="left">Name</TableCell>
                                    <TableCell align="left">Email</TableCell>
                                    <TableCell align="right"></TableCell>
                                </TableRow>
                            </TableHead>

                            {loading === 'loaded' && userData !== '' && userData.map((obj, idx) => (
                                obj.role === 'approver' || obj.role === 'admin' ? (
                                    <TableBody key={obj.idx}>
                                        <TableCell align="left">
                                            <Chip color="primary" label={`${obj.role}`} />
                                        </TableCell>
                                        <TableCell align="left">
                                            <TextField
                                                id="outlined-basic"
                                                variant="outlined"
                                                sx={{ width: '100%' }}
                                                placeholder={`${obj.name}, ${obj.surname}`}
                                                value={userData.name}
                                                disabled
                                                onChange={(e) => handleChange({id:obj._id, name:e.target.value, email: obj.email})}
                                            />
                                        </TableCell>
                                        <TableCell align="left">
                                            <TextField
                                                id="outlined-basic"
                                                variant="outlined"
                                                sx={{ width: '100%' }}
                                                placeholder={obj.email}
                                                value={userData.email}
                                                onChange={(e) => handleChange({ email: e.target.value, id: obj._id })}
                                            />
                                        </TableCell>
                                        <TableCell align="right">
                                            <Stack direction={'row'} spacing={2} justifyContent="flex-end">
                                                <Button
                                                    onClick={() => handleSubmit(obj._id)}
                                                    variant="outlined" size="medium" sx={{ textTransform: 'unset', borderRadius: '10px' }}>Αποθήκευση</Button>
                                            </Stack>
                                        </TableCell>
                                    </TableBody>
                                ) : ''
                            ))}

                        </Table>
                    </Stack>
                </Container>
            </Grid>
        </Grid>
    )
}

export default AdminTools