import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from '../../axios';


// Get all orders
export const fetchOrders = createAsyncThunk(
    '/orders',
    async () => {
        const { data } = await axios.get('/orders')
        return data;
    },
);

const initialState = {
    items: null,
    status: 'loading',
};

const orderSlice = createSlice({
    name: 'Order',
    initialState,
    extraReducers: (builder) => {
        builder
            // Get orders
            .addCase(fetchOrders.pending, (state) => {
                state.status = 'loading';
                state.items = null;
            })
            .addCase(fetchOrders.fulfilled, (state, action) => {
                state.status = 'loaded';
                state.items = action.payload;
            })
            .addCase(fetchOrders.rejected, (state, action) => {
                state.status = 'error';
                state.items = null;
            })
    }
});

export const orderReducer = orderSlice.reducer;
