import React, { useEffect } from "react"

import { useNavigate, Link } from 'react-router-dom';

// Redux 
import { useDispatch, useSelector } from 'react-redux'
import { fetchRegister, selectIsAuth } from "../../redux/slices/Auth";

// MUI
import { Button, TextField, Box, Grid, Typography, Modal, Stack } from '@mui/material';

// Toast
import { toast } from 'react-toastify';

// Components
import Image from '../../components/Helper/Image'

// React form
import { useForm } from 'react-hook-form'

// Images
import backgroundImage from '../../assets/img/ELEMENTS-16.png'
import backgroundLogo from '../../assets/img/logo_black.png'


// Modal styles component
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: '20px'
};

const Register = () => {

    const navigate = useNavigate()

    const isAuth = useSelector(selectIsAuth);
 
    const dispatch = useDispatch();

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm({
        defaultValues: {
            name: "",
            email: "",
            surname: "",
            password: "",
            phone: "",
            address: "",
        },
        mode: 'onChange',
    });

    const onSubmit = async (values) => {
        const data = await dispatch(fetchRegister(values));

        if (!data.payload) {
            return toast.error('Πρόβλημα με την εγγραφή', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                progress: undefined,
                theme: "light",
            });
        }

        handleOpen()
    }

    const handleCloseModal = () => {
        handleClose()
        navigate('/login')
    }

    return (
        <Grid container component="main" sx={{ height: '100vh', display: 'flex', justifyContent: 'center' }}>
            <Grid
                item
                xs={false}
                sm={4}
                md={6}
                sx={{
                    backgroundColor: `#fff`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    position: 'relative'
                }}
            >
                <Image src={backgroundLogo}
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '80%'
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={8} md={6} elevation={6}>
                <Box
                    sx={{
                        height: '100vh',
                        mx: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center'
                    }}
                >
                    <Box textAlign='left'>
                        <Typography component="h1" variant="h4">ΕΓΓΡΑΦΗ ΣΥΝΕΡΓΑΤΗ</Typography>
                    </Box>
                    <Box noValidate sx={{ mt: 5, input: { color: '#f2c300' } }}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                <Grid item xs={12}>
                                    <Stack direction={'row'} spacing={2}>
                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            label="Όνομα"
                                            name="name"
                                            autoFocus
                                            error={Boolean(errors.name?.message)}
                                            helperText={errors.name?.message}
                                            {...register('name', { required: 'Please enter name' })}
                                        />

                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            name="surname"
                                            label="Επίθετο"
                                            error={Boolean(errors.surname?.message)}
                                            helperText={errors.surname?.message}
                                            {...register('surname', { required: 'Please enter surname' })}
                                        />
                                    </Stack>

                                </Grid>
                                <Grid item xs={12} mt={2}>
                                    <Stack direction={'row'} spacing={2}>
                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            name="email"
                                            label="Email"
                                            type="email"
                                            error={Boolean(errors.email?.message)}
                                            helperText={errors.email?.message}
                                            {...register('email', { required: 'Please enter email' })}
                                        />
                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            name="password"
                                            label="Κωδικός"
                                            type="password"
                                            error={Boolean(errors.password?.message)}
                                            helperText={errors.password?.message}
                                            {...register('password', { required: 'Please enter password' })}
                                        />
                                    </Stack>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="phone"
                                        label="Τηλέφωνο"
                                        error={Boolean(errors.phone?.message)}
                                        helperText={errors.phone?.message}
                                        {...register('phone', { required: 'Please enter phone' })}
                                    />
                                </Grid>
                            </Grid>
                            <Button
                                disabled={!isValid}
                                size="large"
                                fullWidth
                                variant="contained"
                                type="submit"
                                sx={{
                                    mt: 3,
                                    mb: 2,
                                    fontSize: '20px'
                                }}
                            >
                                Αποστολή στοιχείων
                            </Button>
                        </form>
                        <Grid container>
                            <Grid item>
                                <Link to="/login" style={{ color: '#fff' }}>
                                    <Typography variant="body2"
                                        sx={{
                                            textDecoration: "none",
                                            cursor: "pointer"
                                        }}>
                                        Έχετε ήδη λογαριασμό;
                                    </Typography>
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Grid>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h5" component="h2">
                        Ευχαριστούμε
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 3 }}>
                        θα λάβετε ένα ηλεκτρονικό μήνυμα το οποίο θα επιβεβαιώνει τη εγγραφή σας
                    </Typography>
                    <Button
                        onClick={handleCloseModal}
                        size="large"
                        fullWidth
                        variant="contained"
                        sx={{
                            mt: 5,
                            mb: 2,
                            fontSize: '20px'
                        }}
                    >
                        Κλείσιμο
                    </Button>
                </Box>
            </Modal>
        </Grid >
    );
}

export default React.memo(Register)