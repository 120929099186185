import { configureStore } from '@reduxjs/toolkit'
import { authReducer } from './slices/Auth'
import { cartReducer } from './slices/CartSlice'
import { orderReducer } from './slices/Order'
import { userReducer } from './slices/User'

const store = configureStore({
    reducer: {
        auth: authReducer,
        cart: cartReducer,
        order: orderReducer,
        user: userReducer,
    },
})

export default store