import { Box } from '@mui/material'

// Components
import Topbar from '../../components/Layout/Topbar'
import Sidebar from '../../components/Layout/Sidebar'
import MainCanvas from '../../components/Layout/MainCanvas'

const Customize = () => {
  return (
    <Box sx={{ display: 'flex' }}>
      <Topbar />
      <Sidebar />
      <MainCanvas />
    </Box>
  )
}

export default Customize