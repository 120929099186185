import React, { useEffect, useState } from 'react'
import { useLocation } from "react-router-dom"
import { useCustomization } from "../../Context/CustomizerContext";
import { Stack, Typography, Box, Divider, Button, styled, IconButton, ButtonGroup, Radio, RadioGroup, FormControl, FormControlLabel, FormLabel, Modal } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

// Axios
import axios from '../../axios'

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { addCartItem } from '../../redux/slices/CartSlice';

// Icons
import CloseIcon from '@mui/icons-material/Close';

// Images
import apon_sizes from './../../assets/img/apon-sizes.jpg'

// Components
import Image from '../../components/Helper/Image';
import ButtonBack from '../../components/Helper/ButtonBack';

// Tostofy
import { ToastContainer, toast } from 'react-toastify';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const Configurator = () => {

    const items = useSelector((state) => state.cart.items)

    const { state } = useLocation();
    const dispatch = useDispatch()

    const [quntityCart, setQuntityCart] = useState(1);
    const [count, setCount] = useState(1)
    const [size, setSize] = useState('M')

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleIncrement = () => {
        setCount(Math.min(count + 1, 3));
    };

    const handleDecrement = () => {
        setCount(Math.max(count - 1, 1));
    };

    useEffect(() => {
        function filterByCity(arr, category) {
            return arr.filter(function (item, i, arr) {
                return (item.category == category);
            });
        };
        var categoryName = filterByCity(items, 'ΚΙΜΟΝΟ');
        categoryName.map((user) => setQuntityCart(Math.min(quntityCart + user.quntity)))
    }, [items])

    const addItemHandler = () => {

        if (quntityCart >= 4) {
            toast.success('Maximum cart', {
                position: "top-center",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                progress: undefined,
                theme: "light",
            });
        } else {
            const item = {
                id: uuidv4(),
                title: state.title,
                color: holderAponColor.color,
                colorName: holderAponColor.name,
                logo: logoClientUrl === '/logo/clear.png' ? 'ΧΩΡΙΣ LOGO' : logoClientUrl,
                extraLogo: logoUrl,
                category: state.category,
                pattern: paternPocket,
                size: size,
                quntity: count,
            }
            dispatch(addCartItem(item))

            toast.success('Προστέθηκε στο καλάθι', {
                position: "top-center",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                progress: undefined,
                theme: "light",
            });
        }

    }


    const {
        holderAponColors,
        holderAponColor,
        setHolderAponColor,

        paternPocket,
        paternsPocket,
        setPaternPocket,
        logoClientUrl,
        setLogoClientUrl,

        logos,
        logoUrl,
        setLogoUrl
    } = useCustomization();

    const handleChangeFile = async (event) => {
        try {
            const formData = new FormData();
            const file = event.target.files[0]
            formData.append("image", file);
            const UploadImage = await axios.post('/upload', formData)

            console.log(UploadImage)
            setLogoClientUrl(UploadImage.data.url)
        } catch (err) {
            console.warn(err);
            alert('Error upload file')
        }
    }

    const handleRemoveFile = () => {
        setLogoClientUrl('/logo/clear.png');
    }

    return (
        <Stack sx={{ p: 3, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100vh' }}>
            <Stack>
                <Stack py={2}>
                    <Typography variant='h3' mb={2} sx={{ fontSize: '24px', fontWeight: '700' }}>{state.name}</Typography>
                    <Typography variant='body'>{state.description}</Typography>
                </Stack>
                <Divider />
                <Stack py={3}>
                    <Typography variant='h5' mb={1} sx={{ fontSize: '18px' }}>Τιράντες ποδιάς</Typography>
                    <Stack direction='row' spacing={3} py={1}>
                        {holderAponColors.map((item, index) => (
                            <Box
                                sx={{
                                    cursor: "pointer",
                                    border: item.color === holderAponColors.color ? "4px solid #fff" : "4px solid #fff",
                                    outline: item.color === holderAponColor.color ? "4px solid #ced0d1" : "none",
                                }}
                                key={index}
                                height={35}
                                width={35}
                                backgroundColor={item.color}
                                borderRadius="50%"
                                onClick={() => setHolderAponColor(item)}
                            />
                        ))}
                    </Stack>
                </Stack>
                <Divider />
                <Stack py={3}>
                    <Typography variant='h5' mb={1} sx={{ fontSize: '18px' }}>Λογότυπο πελάτη</Typography>
                    <Stack direction='row' spacing={3} py={1}>
                        <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
                            <Button component="label" variant="contained" size="large"
                                sx={{
                                    background: '#f2c301',
                                    "&.MuiButtonBase-root:hover": {
                                        bgcolor: "#f2c301"
                                    }
                                }}>
                                Upload logo
                                <VisuallyHiddenInput onChange={handleChangeFile} type="file" />
                            </Button>
                            <IconButton onClick={handleRemoveFile}>
                                <CloseIcon sx={{ color: '#949d9f' }} />
                            </IconButton>
                        </Stack>
                        <Divider orientation="vertical" />
                        <Stack>
                            <Image src={logoClientUrl} sx={{ width: '250px' }} />
                        </Stack>
                    </Stack>
                </Stack>
                <Divider />
                <Stack py={3}>
                    <Typography variant='h5' mb={1} sx={{ fontSize: '18px' }}>Pattern στην τσέπη</Typography>
                    <Stack direction='row' spacing={2} py={1}>
                        {paternsPocket.map((item, index) => (
                            <Box
                                sx={{
                                    cursor: "pointer",
                                    border: "5px solid #fff",
                                    outline: item.name === paternPocket.name ? "4px solid #ced0d1" : "none",
                                }}
                                key={index}
                                height={95}
                                width={95}
                                borderRadius="15px"
                                style={{
                                    backgroundImage: `url(/patern/${item.name}.png)`,
                                    backgroundColor: '#231f20',
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center'
                                }}
                                onClick={() => setPaternPocket(item)}
                            />

                        ))}
                    </Stack>
                </Stack>
                {paternPocket.name === 'PATTERN-03' ? (
                    <Stack py={3}>
                        <Typography variant='h5' mb={1} sx={{ fontSize: '18px' }}>Logo στην τσέπη</Typography>
                        <Stack direction='row' spacing={2} py={1}>
                            {logos.map((item, index) => (
                                <Box
                                    sx={{
                                        cursor: "pointer",
                                        border: "5px solid #fff",
                                        outline: item.name === logoUrl.name ? "4px solid #ced0d1" : "none",
                                    }}
                                    key={index}
                                    height={95}
                                    width={95}
                                    borderRadius="15px"
                                    style={{
                                        backgroundImage: `url(/logo/${item.name}.png)`,
                                        backgroundColor: '#231f20',
                                        backgroundSize: '120%',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: 'center'
                                    }}
                                    onClick={() => setLogoUrl(item)}
                                />
                            ))}
                        </Stack>
                    </Stack>
                ) : ''}
                <Divider />
                {/* <Stack direction='row' spacing={2} py={3}>
                    <Button
                        onClick={handleOpen}
                        component="label" variant="contained" size="medium"
                        sx={{
                            background: '#f2c301',
                            "&.MuiButtonBase-root:hover": {
                                bgcolor: "#f2c301"
                            }
                        }}>
                        Διαστάσεις
                    </Button>
                </Stack>
                <Divider /> */}
                <Stack py={3}>
                    <Typography variant='h5' sx={{ fontSize: '18px' }}>Ποσότητα</Typography>
                    <Typography variant='caption'>*Μέγιστη ποσότητα 3 τεµάχια</Typography>
                    <Stack direction='row' spacing={2} py={3}>
                        <ButtonGroup size="medium" direction='row' sx={{ alignItems: 'center' }}>
                            {<Button onClick={handleDecrement} variant="contained">-</Button>}
                            {<Typography variant="h5" px={2} sx={{ fontSize: '16px' }}>{count}</Typography>}
                            <Button onClick={handleIncrement} variant="contained">+</Button>
                        </ButtonGroup>
                    </Stack>
                </Stack>
            </Stack>

            <Stack py={2}>
                <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} >
                    <ButtonBack />
                    <Button
                        onClick={addItemHandler}
                        component="label"
                        variant="contained"
                        size="large"
                        sx={{
                            padding: '8px 40px',
                            background: '#000000',
                            color: '#ffffff',
                            "&.MuiButtonBase-root:hover": {
                                bgcolor: "#000000"
                            }
                        }}>
                        Προσθήκη
                    </Button>
                </Stack>
            </Stack>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Image src={apon_sizes} />
                </Box>
            </Modal>
        </Stack >
    )
}

export default Configurator