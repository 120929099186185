import React from 'react'

// MUI
import { Stack, Box, Typography, IconButton, Divider } from '@mui/material'

// Icons
import DeleteIcon from '@mui/icons-material/Delete';

// redux
import { useDispatch, useSelector } from 'react-redux'
import { removeCartItem } from '../../redux/slices/CartSlice'

const CartItem = (item) => {

    const dispatch = useDispatch()
    const items = useSelector((state) => state.cart.items)

    const deleteItem = (id) => {
        dispatch(removeCartItem(id))
    }

    return (
        <>
            <Stack my={2} direction='row' sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                <Box>
                    <Typography variant='body'>{item.title} {item.category}</Typography>
                </Box>
                <Box>
                    <Typography variant='body1' sx={{ fontSize: '14px ' }}>{item.size}</Typography>
                </Box>
                <Box>
                    <Typography variant='body1' sx={{ fontSize: '14px ' }}>{item.quntity} τεμ.</Typography>
                </Box>
                <IconButton edge="end" aria-label="delete" onClick={() => deleteItem(item.id)}>
                    <DeleteIcon color='error' />
                </IconButton>
            </Stack>
            <Divider />
        </>
    )
}

export default CartItem