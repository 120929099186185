import React, { useState } from 'react';
import { Navigate, Link } from 'react-router-dom';

// Redux 
import { useDispatch, useSelector } from 'react-redux'
import { fetchAuth, selectIsAuth, updateAuthPass } from "../../redux/slices/Auth";

// MUI
import { Button, TextField, Box, Grid, Typography, Modal } from '@mui/material';

// Toast
import { toast } from 'react-toastify';

// Components
import Image from '../../components/Helper/Image'

// React form
import { useForm } from 'react-hook-form'

// Images
import backgroundLogo from '../../assets/img/logo_black.png'
import LinkImg from '../../assets/img/image001.png'

import axios from './../../axios'

import { v4 as uuidv4 } from 'uuid';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const Login = () => {

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [emailRequest, setEmailRequest] = useState('');

  const isAuth = useSelector(selectIsAuth);
  const passUid = useSelector((state) => state.auth.uidPass);

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
    mode: 'onChange',
  });

  const onSubmit = async (values) => {
    const data = await dispatch(fetchAuth(values));

    if (!data.payload) {
      return toast.error('Το προφιλ σας δεν είναι ενεργοποιημένο', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        progress: undefined,
        theme: "light",
      });
    }

    if ('token' in data.payload) {
      window.localStorage.setItem('token', data.payload.token);
    }
  }

  if (isAuth && isAuth.isActivated) {
    return <Navigate to='/home' />
  }

  const handleSendPass = async () => {
    const data = {
      email: emailRequest,
      uid: uuidv4()
    }

    const res = await dispatch(updateAuthPass(data));

    if (!res.payload) {
      return toast.error('Το προφιλ σας δεν είναι ενεργοποιημένο', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        progress: undefined,
        theme: "light",
      });
    }
  }

  return (
    <>
      <Grid container component="main" sx={{ height: '100vh', overflowY: 'hidden' }}>
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundColor: `#fff`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            position: 'relative'
          }}
        >
          <Image src={backgroundLogo}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '80%'
            }}
          />
        </Grid>
        <Grid item xs={12} sm={8} md={5} elevation={6}>
          <Box
            sx={{
              height: '100vh',
              mx: 8,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}
          >
            <Box mb={5} textAlign='center'>
              <Typography component="h1" variant="h4">PROFESSIONAL</Typography>
              <Typography component="h1" variant="h4" sx={{ color: '#f2c300' }}>CLOTHING</Typography>
              <Typography component="h1" variant="h4">APPLICATION</Typography>
            </Box>
            <Box noValidate sx={{ mt: 5, input: { color: '#f2c300' } }}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <TextField
                  margin="normal"
                  fullWidth
                  label="Email"
                  type='email'
                  autoFocus
                  error={Boolean(errors.email?.message)}
                  helperText={errors.email?.message}
                  {...register('email', { required: 'Please enter email' })}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  error={Boolean(errors.password?.message)}
                  helperText={errors.password?.message}
                  {...register('password', { required: 'Please enter password' })}
                />
                <Button
                  disabled={!isValid}
                  size="large"
                  fullWidth
                  variant="contained"
                  type='submit'
                  sx={{
                    mt: 3,
                    mb: 2,
                    fontSize: '20px'
                  }}
                >
                  Σύνδεση
                </Button>
              </form>
              <Grid container>
                <Grid item xs>
                  <Typography
                    onClick={handleOpen}
                    variant="body2"
                    sx={{
                      textDecoration: "none",
                      cursor: "pointer"
                    }}>
                    Ξέχασα το password
                  </Typography>
                </Grid>
                <Grid item>
                  <Link to="/register" style={{ color: '#fff' }}>
                    <Typography variant="body2"
                      sx={{
                        textDecoration: "none",
                        cursor: "pointer"
                      }}>
                      Δεν έχετε λογαριασμό;
                    </Typography>
                  </Link>
                </Grid>
              </Grid>

              <Grid constainer style={{ marginTop: '50px', textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
                Intellectual property of <Link to="https://link-worldwide.com/" style={{ fontWeight: 'bold' }}>
                  <Image src={LinkImg}
                    sx={{
                      position: 'relative',
                      width: '100px',
                      marginLeft: '10px'
                    }}
                  />
                </Link>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid >


      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Αλλαγή συνθηματικού
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <TextField
              value={emailRequest}
              onChange={(e) => setEmailRequest(e.target.value)}
              margin="normal"
              required
              fullWidth
              name="email"
              label="email"
              type="email"
            />
            <Button
              onClick={handleSendPass}
              size="large"
              fullWidth
              variant="contained"
              type='submit'
              sx={{
                mt: 3,
                mb: 2,
                fontSize: '20px'
              }}
            >
              Αποστολή
            </Button>
          </Typography>
        </Box>
      </Modal>
    </>
  );
}

export default React.memo(Login)