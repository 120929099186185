import { createContext, useContext, useState } from "react";


//New context
const collarColors = [
  {
    color: "#11161a",
    name: "ΜΑΥΡΟ",
  },
  {
    color: "#d79604",
    name: "ΚΙΤΡΙΝΟ",
  },
];

const paternsPocket = [
  {
    name: "PATTERN-01",
  },
  {
    name: "PATTERN-02",
  },
  {
    name: "PATTERN-03",
  },
];

const holderAponColors = [
  {
    color: "#1c1c1c",
    name: "ΜΑΥΡΟ",
  },
  {
    color: "#d79604",
    name: "ΚΊΤΡΙΝΟ",
  },
];

const logos = [
  {
    name: "LOGO-03",
  },
  {
    name: "LOGO-02",
  },
  {
    name: "clear",
  },
];

const Customization = createContext({});

export const CustomizationProvider = (props) => {


  const [holderAponColor, setHolderAponColor] = useState(holderAponColors[0]);

  const [collarColor, setCollarColor] = useState(collarColors[1]);
  const [logoClientUrl, setLogoClientUrl] = useState('/logo/clear.png');

  const [logoUrl, setLogoUrl] = useState(logos[0]);

  const [paternPocket, setPaternPocket] = useState(paternsPocket[0]);


  return (
    <Customization.Provider
      value={{
        // Collar color
        collarColors,
        collarColor,
        setCollarColor,
        // Logo client
        logoClientUrl,
        setLogoClientUrl,
        // Patern IMG
        paternPocket,
        paternsPocket,
        setPaternPocket,
        // ΤΙΡΑΝΤΕΣ ΠΟΔΙΑΣ
        holderAponColors,
        holderAponColor,
        setHolderAponColor,

        logos,
        logoUrl,
        setLogoUrl
      }}
    >
      {props.children}
    </Customization.Provider>
  );
};

export const useCustomization = () => {
  const context = useContext(Customization);
  return context;
};
