import React, { useEffect, useState } from 'react'

import { Container, TextField, Paper, Button, Stack, Grid } from '@mui/material';

// Redux 
import { useDispatch, useSelector } from 'react-redux'
import { updateAuth, fetchAuthMe } from "../../../../redux/slices/Auth";

// React form
import { useForm } from 'react-hook-form'

// Toast
import { toast } from 'react-toastify';

const Profile = () => {

  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.data)
  const loading = useSelector((state) => state.auth.status)

  const [userName, setUserName] = useState()
  const [userSurname, setUserSurname] = useState()
  const [userPhone, setUserPhone] = useState()
  const [loader, setLoader] = useState(false)

  useEffect(() => {
    setLoader(false)
    dispatch(fetchAuthMe())
  }, [loader])

  const onSubmit = async () => {
    const data = {
      name: userName,
      surname: userSurname,
      phone: userPhone
    }
    await dispatch(updateAuth(data, user._id));
    setLoader(true)
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12} lg={12}>
        <Container maxWidth="false" component={Paper} sx={{ borderRadius: '10px' }}>
          <Stack pt={2}>
            {loading === 'loaded' ? (
              <form>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  <Grid item xs={6}>
                    <TextField
                      margin="normal"
                      fullWidth
                      name="name"
                      autoFocus
                      size='small'
                      onChange={(e) => setUserName(e.target.value)}
                      defaultValue={user.name}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      margin="normal"
                      fullWidth
                      name="surname"
                      size='small'
                      onChange={(e) => setUserSurname(e.target.value)}
                      defaultValue={user.surname}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      margin="normal"
                      fullWidth
                      name="phone"
                      size='small'
                      onChange={(e) => setUserPhone(e.target.value)}
                      defaultValue={user.phone}
                    />
                    <Button variant="contained" onClick={onSubmit} size="medium" sx={{ textTransform: 'unset', marginTop: '30px', mb: 3, borderRadius: '10px' }}>Αποθηκευση</Button>
                  </Grid>
                </Grid>
              </form>
            ) : ''}
          </Stack>
        </Container>
      </Grid>
    </Grid>
  )
}

export default React.memo(Profile)