import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from '../../axios';


// Get all users
export const fetchUsers = createAsyncThunk(
    '/users',
    async () => {
        const { data } = await axios.get('/users')
        return data;
    },
);

const initialState = {
    users: null,
    status: 'loading',
};

const userSlice = createSlice({
    name: 'User',
    initialState,
    extraReducers: (builder) => {
        builder
            // Get orders
            .addCase(fetchUsers.pending, (state) => {
                state.status = 'loading';
                state.items = null;
            })
            .addCase(fetchUsers.fulfilled, (state, action) => {
                state.status = 'loaded';
                state.items = action.payload;
            })
            .addCase(fetchUsers.rejected, (state, action) => {
                state.status = 'error';
                state.items = null;
            })
    }
});

export const userReducer = userSlice.reducer;