import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "#212121", //#11161a
    },
    primary: {
      main: "#ffffff",
    },
    secondary: {
      main: "#f2c301"
    }
  },
  components: {
    MuiIconButton: {
      defaultProps: {
        size: "small",
      },
    },
    MuiSnackbar: {
      defaultProps: {
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        message: {
          fontWeight: 600,
          textTransform: "capitalize",
        },
      },
    },
  },
  typography: {
    button: {
      textTransform: "unset",
      fontFamily: 'PFDinTextCond-Medium',
    },

    h2: {
      fontFamily: 'PFDinTextCond-light',
      fontSize: '42px'
    },

    h3: {
      fontFamily: 'CeraGRBlack',
      fontSize: '24px'
    },

    h4: {
      fontFamily: 'CFGaramondMed',
      fontWeight: 500
    },

    h5: {
      fontFamily: 'PFDinTextCond-Bold'
    },

    h6: {
      fontFamily: 'CFGaramondMed'
    },

    body: {
      fontSize: '16px',
      fontFamily: 'PFDinTextCond-Medium',
      fontWeight: 500,
      textDecoration: "none",
      lineHeight: 1.3,
      color: '#949d9f'
    },

    body2: {
      fontSize: '18px',
      fontFamily: 'PFDinTextCond-light',
      textDecoration: "none",
    },

    caption: {
      fontSize: '14px',
      fontFamily: 'PFDinTextCond-Medium',
      fontWeight: 500,
      textDecoration: "none",
      lineHeight: 1.3,
      color: '#949d9f'
    }
  },

  shape: {
    borderRadius: 10,
  },

});

export default theme;