import React, { useState, useEffect } from 'react';

import { TableContainer, Paper, Grid } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux'
import { fetchOrders } from "../../../../redux/slices/Order";

import AdminTable from './Table/AdminTable';
import SellerTable from './Table/SellerTable';
import ApproverTable from './Table/ApproverTable';

const Orders = () => {

  const dispatch = useDispatch();
  const userAuth = useSelector((state) => state.auth.data)

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    dispatch(fetchOrders())
    setLoading(false)
  }, [loading])

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12} lg={12}>
        <TableContainer component={Paper} sx={{ borderRadius: '10px' }}>
          {
            userAuth && userAuth.role === 'admin' ? (
              <>
                <AdminTable />
              </>
            ) : ('')}
          {userAuth && userAuth.role === 'seller' ? <SellerTable /> : ''}
          {userAuth && userAuth.role === 'approver' ? <ApproverTable /> : ''}
        </TableContainer>
      </Grid>
    </Grid>
  )
}

export default Orders