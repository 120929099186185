import { Navigate } from 'react-router-dom';

// Redux 
import { useSelector } from 'react-redux'
import { selectIsAuth } from "../redux/slices/Auth";

const ProtectedRoute = ({ children }) => {

    const isAuth = useSelector(selectIsAuth);

    if (!window.localStorage.getItem('token') && !isAuth) {
        return <Navigate to='/' />
    }
    return children
}

export default ProtectedRoute